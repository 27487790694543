<template>
  <div>
    <SettingsNavigation />

    <div
      class="max-w-screen-xl px-6 mx-auto"
    >
      <BaseCard>
        <header class="items-center justify-between sm:flex">
          <div class="flex items-center">
            <span
              class="flex flex-col items-center justify-center w-10 h-10 mr-4 text-white rounded-full bg-secondary"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
            <h2 class="text-lg">
              FAQs
            </h2>
          </div>
        </header>

        <div class="py-6 bg-white">
          <dl class="space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-12 md:space-y-0">
            <div
              v-for="faq in faqs"
              :key="faq.question"
            >
              <dt class="text-lg font-medium leading-6">
                {{ faq.question }}
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                {{ faq.answer }}
              </dd>
            </div>
          </dl>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script>
import SettingsNavigation from '@components/Settings/SettingsNavigation'

export default {
  components: {
    SettingsNavigation
  },

  data() {
    return {
      faqs: [
        {
          question: 'How do I start?',
          answer: 'You can immediately get started by choosing one of our plans.'
        },
        {
          question: 'What payment methods are accepted?',
          answer: 'We accept all major credit cards.'
        },
        {
          question: 'What’s the difference between a test and an assessment?',
          answer: 'An assessment comprises one or more tests. So, an assessment could just include one test like a numerical reasoning test, or it could include multiple tests such as a numerical reasoning test, an excel test and a personality test. An assessment can include up to 6 different tests.'
        },
        {
          question: 'Can Test Candidates be integrated with other platforms?',
          answer: 'Yes, we have an API that can be used to integrate Test Candidates with another of other platforms such as Applicate Tracking Systems or Jobs Boards.'
        },
        {
          question: 'How does Test Candidates protect my data?',
          answer: 'Our platform is securely hosted on Amazon Web Services. Alongside AWS, we implement our own security to ensure peace of mind for all our clients.'
        },
        {
          question: 'Do I need to be an expert in psychometric testing?',
          answer: 'We’ve designed Test Candidates to be simple and easy-to-use so regardless of your knowledge of psychometric testing, you’ll find it intuitive.'
        },
        {
          question: 'What support is offered?',
          answer: 'We offer 24/7 online support. If you have any questions, you can check out our Support Centre or contact our support team.'
        }
      ]
    }
  }
}
</script>
