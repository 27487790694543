<template>
  <BaseContainer>
    <Faqs />
  </BaseContainer>
</template>

<script>
import Faqs from '@components/Settings/Faqs'

export default {
  components: {
    Faqs
  },

  page() {
    return {
      title: 'FAQs'
    }
  }
}
</script>
